import {
  Bird,
  Bug,
  CloudRain,
  Coffee,
  Droplet,
  FlameKindling,
  Gauge,
  TrainTrack,
  Waves,
  Wind,
  Zap,
} from 'lucide-react'
import { IEffect } from '@/effect/types.ts'

export const DEFAULT_IOS_VOLUME_VALUE = 80
export const DEFAULT_VOLUME_VALUE = 40

const listEffects = (isIOS: boolean): IEffect[] => {
  return [
    {
      id: 'crackling-fire',
      name: 'Crackling fire',
      icon: FlameKindling,
      file: 'crackling-fire.mp3',
      volume: !isIOS ? DEFAULT_VOLUME_VALUE : DEFAULT_IOS_VOLUME_VALUE,
      mutedVolume: 0,
      isAudioLoaded: false,
      loopTimeoutId: null,
    },
    {
      id: 'rain',
      name: 'Rain',
      icon: CloudRain,
      file: 'rain.mp3',
      volume: !isIOS ? DEFAULT_VOLUME_VALUE : DEFAULT_IOS_VOLUME_VALUE,
      mutedVolume: 0,
      isAudioLoaded: false,
      loopTimeoutId: null,
    },
    {
      id: 'wind',
      name: 'Wind',
      icon: Wind,
      file: 'wind-noise.mp3',
      volume: !isIOS ? DEFAULT_VOLUME_VALUE : DEFAULT_IOS_VOLUME_VALUE,
      mutedVolume: 0,
      isAudioLoaded: false,
      loopTimeoutId: null,
    },
    {
      id: 'water',
      name: 'Water',
      icon: Droplet,
      file: 'water.mp3',
      volume: !isIOS ? DEFAULT_VOLUME_VALUE : DEFAULT_IOS_VOLUME_VALUE,
      mutedVolume: 0,
      isAudioLoaded: false,
      loopTimeoutId: null,
    },
    {
      id: 'sea',
      name: 'Sea',
      icon: Waves,
      file: 'sea.mp3',
      volume: !isIOS ? DEFAULT_VOLUME_VALUE : DEFAULT_IOS_VOLUME_VALUE,
      mutedVolume: 0,
      isAudioLoaded: false,
      loopTimeoutId: null,
    },
    {
      id: 'train',
      name: 'Train',
      icon: TrainTrack,
      file: 'train.mp3',
      volume: !isIOS ? DEFAULT_VOLUME_VALUE : DEFAULT_IOS_VOLUME_VALUE,
      mutedVolume: 0,
      isAudioLoaded: false,
      loopTimeoutId: null,
    },
    {
      id: 'bird',
      name: 'Bird',
      icon: Bird,
      file: 'bird.mp3',
      volume: !isIOS ? DEFAULT_VOLUME_VALUE : DEFAULT_IOS_VOLUME_VALUE,
      mutedVolume: 0,
      isAudioLoaded: false,
      loopTimeoutId: null,
    },
    {
      id: 'coffee-shop',
      name: 'Coffee shop',
      icon: Coffee,
      file: 'coffee-shop.mp3',
      volume: !isIOS ? DEFAULT_VOLUME_VALUE : DEFAULT_IOS_VOLUME_VALUE,
      mutedVolume: 0,
      isAudioLoaded: false,
      loopTimeoutId: null,
    },
    {
      id: 'thunder',
      name: 'Thunder',
      icon: Zap,
      file: 'thunder.mp3',
      volume: !isIOS ? DEFAULT_VOLUME_VALUE : DEFAULT_IOS_VOLUME_VALUE,
      mutedVolume: 0,
      isAudioLoaded: false,
      loopTimeoutId: null,
    },
    {
      id: 'cricket',
      name: 'Cricket',
      icon: Bug,
      file: 'cricket.mp3',
      volume: !isIOS ? DEFAULT_VOLUME_VALUE : DEFAULT_IOS_VOLUME_VALUE,
      mutedVolume: 0,
      isAudioLoaded: false,
      loopTimeoutId: null,
    },
    {
      id: 'driving',
      name: 'Driving',
      icon: Gauge,
      file: 'driving.mp3',
      volume: !isIOS ? DEFAULT_VOLUME_VALUE : DEFAULT_IOS_VOLUME_VALUE,
      mutedVolume: 0,
      isAudioLoaded: false,
      loopTimeoutId: null,
    },
  ]
}

export default listEffects
